<template>
  <div class="confirm-code">
    <top-bar back backroute="register2" is-auth :img="logo" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="title"><t name="3.10" /></div>
      <div class="subtitle"><t name="3.11" />{{ phone }} <t name="3.12" /></div>
      <code-input :danger="$var('danger')" :received="code" :code.sync="code" />
      <n-button wide color="yellow" @click="next"><t name="3.13" /></n-button>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'

export default {
  name: 'Index',
  data() {
    return {
      logo,
      code: '',
    }
  },
  computed: {
    phone() {
      return this.$route.query.phone
    },
    role() {
      return this.$route.query.role
    },
  },
  methods: {
    next() {
      if (this.code) {
        this.$var('load', true)
        $api.auth.checkSms(this.phone, this.code).then(() => {
          this.$router.push({name: 'citySelect', query: {role: this.role, phone: this.phone, code: this.code}})
        }).catch((err) => {
          this.$var('danger', err.response.data.message)
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.confirm-code {
  padding-top: 100px;

  .title {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 4px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }
}
</style>
